@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Black";
    src: url("/public/Fonts/static/Inter-Black.ttf");
}
@font-face {
    font-family: "Bold";
    src: url("/public/Fonts/static/Inter-Bold.ttf");
}
@font-face {
    font-family: "ExtraBold";
    src: url("/public/Fonts/static/Inter-ExtraBold.ttf");
}
@font-face {
    font-family: "Light";
    src: url("/public/Fonts/static/Inter-Light.ttf");
}
@font-face {
    font-family: "ExtraLight";
    src: url("/public/Fonts/static/Inter-ExtraLight.ttf");
}
@font-face {
    font-family: "Medium";
    src: url("/public/Fonts/static/Inter-Medium.ttf");
}
@font-face {
    font-family: "Regular";
    src: url("/public/Fonts/static/Inter-Regular.ttf");
}
@font-face {
    font-family: "SemiBold";
    src: url("/public/Fonts/static/Inter-SemiBold.ttf");
}
@font-face {
    font-family: "Thin";
    src: url("/public/Fonts/static/Inter-Thin.ttf");
}
@font-face {
    font-family: "Arimo";
    src: url("/public/Fonts/static/Arimo-Regular.ttf");
}
@font-face {
    font-family: "ComicNeue";
    src: url("/public/Fonts/static/ComicNeue-Regular.ttf");
}
@font-face {
    font-family: "CourierPrime";
    src: url("/public/Fonts/static/CourierPrime-Regular.ttf");
}
@font-face {
    font-family: "GothicA1";
    src: url("/public/Fonts/static/GothicA1-Regular.ttf");
}
@font-face {
    font-family: "Lato";
    src: url("/public/Fonts/static/Lato-Regular.ttf");
}
@font-face {
  font-family: 'Montserrat';
  src: url('/public/Fonts/static/Montserrat-Regular.ttf');
}
@font-face {
  font-family: "OpenSans";
  src: url("/public/Fonts/static/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Roboto";
  src: url("/public/Fonts/static/Roboto-Regular.ttf");
}
@font-face {
  font-family: 'Tinos';
  src: url('/public/Fonts/static/Tinos-Regular.ttf');
}

body{
    font-family:'Inter', sans-serif !important;
    /* background-color: #ffffff !important; */
}
button{
  outline: 0;
}
.sort-icon.descending{
  transform: rotate(180deg);
}
.dropdown-cont{
  top: -32px;
}

body.modal-open{
  overflow: hidden;
}

.lots.infinite-scroll-component::-webkit-scrollbar {
 display: none !important;
}
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected
{
  background-color: #343434;
  color: #FFFFFF;
}
.react-datepicker__day--today{
  background-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  display: none;
}
.react-datepicker {
  font-size: 10px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.09);
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 8px;
}
.react-datepicker__header {
    text-align: center !important;
    background-color: #ffffff !important;
    border-bottom: none !important;
    border-top-left-radius: 8px !important;
    padding: 8px 0 !important;
    position: relative !important;
}
.react-datepicker__navigation-icon::before{
  border-color: #282828;
  border-style: solid;
  border-width: 2px 2px 0 0;
}
.react-datepicker__navigation{
  top: 24px !important;
}
.react-datepicker__current-month{
  padding: 15px 0 !important;
}
.react-datepicker__day--selected:hover, 
.react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, 
.react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, 
.react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, 
.react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, 
.react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, 
.react-datepicker__year-text--in-range:hover, .react-datepicker__day--selected{
  background-color: #343434 !important;
  color: #FFFFFF !important;
}
.auction-description .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important;
}
/* Set content font-families */
.ql-font-Arimo {
  font-family: 'Arimo';
}
.ql-font-Comic-Neue {
  font-family: 'ComicNeue';
}
.ql-font-Courier-Prime {
  font-family: 'CourierPrime';
}
.ql-font-Gothic-A1 {
  font-family: 'GothicA1';
}
.ql-font-Lato {
  font-family: 'Lato';
}
.ql-font-Montserrat {
  font-family: 'Montserrat';
}
.ql-font-Open-Sans {
  font-family: 'OpenSans';
}
.ql-font-Roboto {
  font-family: 'Roboto';
}
.ql-font-Tinos {
  font-family: 'Tinos';
}
.ql-font-Inter {
  font-family: 'Inter';
}
/* Some font titles are long */
.ql-snow .ql-picker {
  height: unset !important;
}
/* Set Selector font-families */
.ql-picker-label[data-value="Arimo"]::before, .ql-picker-item[data-value="Arimo"]::before {
  content: "Arimo" !important;
  font-family: 'Arimo', sans-serif !important;
}
.ql-picker-label[data-value="Comic-Neue"]::before, .ql-picker-item[data-value="Comic-Neue"]::before {
  content: "Comic Neue" !important;
  font-family: 'ComicNeue', sans-serif !important;
}
.ql-picker-label[data-value="Courier-Prime"]::before, .ql-picker-item[data-value="Courier-Prime"]::before {
  content: "Courier Prime" !important;
  font-family: 'CourierPrime', sans-serif !important;
}
.ql-picker-label[data-value="Gothic-A1"]::before, .ql-picker-item[data-value="Gothic-A1"]::before {
  content: "Gothic A1" !important;
  font-family: 'GothicA1', sans-serif !important;
}
.ql-picker-label[data-value="Montserrat"]::before, .ql-picker-item[data-value="Montserrat"]::before {
  content: "Montserrat" !important;
  font-family: 'Montserrat', sans-serif !important;
}
.ql-picker-label[data-value="Lato"]::before, .ql-picker-item[data-value="Lato"]::before {
  content: "Lato" !important;
  font-family: 'Lato', sans-serif !important;
}
.ql-picker-label[data-value="Open-Sans"]::before, .ql-picker-item[data-value="Open-Sans"]::before {
  content: "Open Sans" !important;
  font-family: 'OpenSans', sans-serif !important;
}
.ql-picker-label[data-value="Roboto"]::before, .ql-picker-item[data-value="Roboto"]::before {
  content: "Roboto" !important;
  font-family: 'Roboto', sans-serif !important;
}
.ql-picker-label[data-value="Tinos"]::before, .ql-picker-item[data-value="Tinos"]::before {
  content: "Tinos" !important;
  font-family: 'Tinos', sans-serif !important;
}
.ql-picker-label[data-value="Inter"]::before, .ql-picker-item[data-value="Inter"]::before {
  content: "Inter" !important;
  font-family: 'Inter', sans-serif !important;
}
/* set default height of editor */
.ql-editor {
  min-height: 6rem;
}

/* set video fit to the description */
.ql-video {
  width: 100%;
}

.CookieConsent > div:nth-of-type(2) {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.EZDrawer .EZDrawer__container{
  background-color: #282828 !important;
  width: 300px !important;
}
.EZDrawer .EZDrawer__container.mobile-setup{
  background-color: #FFFFFF !important;
  top: 112px!important;
  box-shadow: none!important;
  width: 100% !important;
}
@media(min-width: 768px){
  .EZDrawer .EZDrawer__container{
    visibility: hidden !important;
  }
  .EZDrawer .EZDrawer__overlay{
    display: none !important;
  }
}
.carousel-indicators [data-coreui-target]{
  background-color: #000 !important;
  width: 20px !important;
  border-radius: 40px !important;
}
.carousel-indicators .active{
  width: 50px !important;
}
.carousel-inner{
  border-radius: 10px;
}
.slide {
  opacity: 0;
  display: none;
}
.slide.active {
  opacity: 1;
  visibility: visible;
  display: block;
}
._2vjgV {
  display: none !important;
}
._30Ipg {
  background: #FFFFFF !important;
  border: 1px solid #A1A1A9 !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0) !important;
  border-radius: 0 !important;
}
._14Kkd {
  font-size: 10px !important;
  font-weight: 400 !important;
}
._34ETa {
  margin-bottom: 16px !important;
}
._3_9HH {
  border-radius: 63px !important;
  background: #FFFFFF !important;
  padding: 5px 15px !important;
  width: 100% !important;
  border: 1px solid #DFDFDF !important;
  font-size: 10px !important;
  height: 40px !important;
}
._3_9HH:focus {
  border: 1px solid #343434 !important;
}
._30Ipg, ._1Lxpd{
  width: 100% !important;
}
._30Ipg{
  height: 44px !important;
}
._3Dx_t{
  margin-left: 0 !important;
  font-size: 14px !important;
}
.css-13cymwt-control, .css-t3ipsp-control{
  min-height: 100% !important;
  border-color: #ffffff !important;
  border-radius: 0 !important;
  box-shadow: 0 0 0 1px transparent !important;
  width: 100px !important;
  position: relative;
}
#react-select-2-listbox{
  min-width: 330px !important;
  position: absolute !important;
  left: 0 !important;
  display: block !important;
  /* height: 30px !important; */
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
@media(max-width: 1080px){
  #react-select-2-listbox{
    min-width: 250px !important;
  }
}
@media(max-width: 844px){
  #react-select-2-listbox{
    min-width: 300px !important;
  }
}
.css-t3ipsp-control:focus, .css-t3ipsp-control:hover {
  border-color: #fff!important ;
  box-shadow: 0 0 0 1px transparent !important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.profile-section.active, .profile-section.active:hover{
  border-color: #282828;
}
/* custom scroll bar */
.customScrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}
.customScrollbar::-webkit-scrollbar:vertical{
  width: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px !important;
  height:42px;
}
.customScrollbar::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px !important;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
  border-radius: 22px;
  height: 8px;
}
/* checkbox */
/*  checkbox for google captcha */
.custom-checkbox.checkbox {
  /* width: 100%; */
  margin: 0;
  position: relative;
  display: block;
}
.custom-checkbox.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}
.custom-checkbox.checkbox label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  height: 40px;
  width: 40px;
  background: white;
  transition: transform 0.28s ease;
  border: 3px solid #C1C1C1;
  border-radius: 3px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* check box for terms and condition */
.checkbox.checkbox-terms input{
  opacity: 0;
}
.checkbox.checkbox-terms input[type="checkbox"]:checked ~ label::before {
  background-color: #000 !important;
  border-radius: 4px;
  border: 1px solid #000;
}
.checkbox.checkbox-terms label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  margin: 0;
  height: 15px;
  width: 15px;
  border-radius: 4px;
  transition: transform 0.28s ease;
  transform: translateY(-50%);
  border: 1px solid #A1A1A9;
}
.checkbox.checkbox-terms input[type="checkbox"]:checked ~ label::after {
  transform: rotate(-45deg) scale(1);
}
.checkbox.checkbox-terms label::after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 8px;
  left: 5px;
}

@media(min-width:641px){
  .dropdown-cont{
      transform: translate3d(-13px, 1px, 0px) !important;
  }
  }
  /* dropdown content */
  @media(max-width:640px){
      .dropdown-cont{
          transform: translate3d(-42px, -14px, 0px) !important;
      }
  }
  .tippy-tooltip-content{

  position:absolute;
  left: -80px;
  top: -1px;
  width:250px;
  padding: 10px;
  font-size:14px;
  font-weight:500;
  color:#fff;
  background:#000;
  border-radius:8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
/* .css-1nmdiq5-menu{
  position: absolute;
  top: 0;
  width: 200px;
  min-height: 500px !important;
  margin: 0;
} */

.table{
  border-top-left-radius: 9px;
}

.react-multiple-carousel__arrow--left::before{
    content: url('../public/images/arrow-left-dark.svg') !important;
}
.react-multiple-carousel__arrow--right::before{
    content: url('../public/images/arrow-right-dark.svg') !important;
}
.react-multiple-carousel__arrow{
    background-color: transparent !important;
}

.react-multi-carousel-track {
    margin-bottom: 70px !important;
}

.single-lot .react-multiple-carousel__arrow{
    bottom: 0;
}

.react-multiple-carousel__arrow{
top: 376px !important;
}

.previewlot .react-multiple-carousel__arrow{
  z-index: 2 !important;
}
/* @media (max-width: 768px) {
  .react-multiple-carousel__arrow{
   display: none;
}

} */

.sidebar {
  height: 100%;
  background-color: #333;
  padding: 90px;
  width: 200px;
  transition: transform 0.3s ease;
  transform: translateX(-250px);
  position: fixed;
  z-index: 100;
}

.sidebar.open {
  transform: translateX(0);
  overflow-y: hidden;
  position: fixed;
}

.main.open {
  padding: 0px 16px 16px;
  transition: margin-left 0.3s ease, width 0.3s ease; /* Add transition for width */
  margin-left: 80px;
  width: calc(100% - 16px); /* Initial width, adjust as needed */
}

.main {
  margin-left: 250px;
  width: calc(100% - 250px -16px); /* Adjust width when sidebar is open */
}

@media(max-width:748px) {
.main.open {
  width: 100%; 
  padding: 0px !important;
}
.sidebar {
 
  padding: 0px !important;
  width: 0px !important;

}
.main {
  margin-left: 0px !important;
  width: auto !important;
}

}

.hidden-text {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }

.readonly {
  color: #718096; /* Text color for read-only part */
  pointer-events: none; /* Disable pointer events */
}


.carousel {

  width: 100%;
  
}

.carousel ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
}

.carousel li {
  width: 25%; /* adjust the width to fit your needs */
  margin: 0 10px;
}

.carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-itemoflot {
  margin: 10px; /* add margin between carousel items */
}

.carousel-itemoflot img {
  object-fit: cover; /* ensure images are fully covered */
  height: 350px; /* set a fixed height for images */
  width: 100%; /* set width to 100% */
}

/* Add media queries for smaller screens */
@media (max-width: 768px) {
  .carousel-itemoflot {
    margin: 5px; /* reduce margin on smaller screens */
  }
  .carousel-itemoflot img {
    height: 250px; /* reduce image height on smaller screens */
  }
}

.overflow-hidden {
  overflow: hidden;
}

input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('/public/images/calender2.svg'); 
    background-size: 20px 20px; /* adjust the size to fit your design */
    width: auto;
    background-position: right 10px center;
    background-repeat: no-repeat;
}



html{
overflow: auto !important;
padding-right: 0px !important;
}.rendered-content a {
  text-decoration: underline;
  color: #307ED3;
}

.more-lines{
  overflow: hidden;
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
word-break: break-all;
}

/* .vega-toolbar-section-left .vega-toolbar-section .header-sn-styles9{
 display: none !important;
} */
/* .header-sn-styles3{
 display: none !important;
} */

.header-sn-styles3 {
    color: #212121;
    font-size: 0.75rem;
    box-sizing: border-box;
    font-family: "Amazon Ember", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.15px;
    background-color: unset;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: none !important;
}

html{
overflow: auto !important;
padding-right: 0px !important;
}
/* Tooltip container styling */
/* .tooltip-container[data-tooltip] {
    position: relative;
    cursor: pointer none;
    opacity: 2;
} */

/* Tooltip styling */
/* .tooltip-container[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 30%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 2;
    visibility: visible;
} */

/* .tooltip-container::after {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
} */


/* Ensure Tab is styled properly */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer none; /* For disabled Tab */
  opacity: 2;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  font-size: 12px;
  white-space: nowrap;
  position: absolute;
  bottom: 110%; /* Position above the Tab */
  left: 10%;
  transform: translateX(-40%);
  z-index: 10;
  transition: opacity 0.3s, visibility 0.3s;
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.highlight-link {
    color: blue;
    text-decoration: underline;
}